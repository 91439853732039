// Contact form loading 
$( document ).ready(function() {
	$('#contact-form-submit').on('click', function() {
		var empty_field = false;
		$('.input-field[required]').each(function(iteration, element) {
			if($(element).val().length === 0) {
				empty_field = true;
			}
		});
		
		if(!empty_field) {
			$(this).html('Processing...');
			$(this).css('cursor', 'progress');
		}
	});
});