
// ----------------------------------------------------------------------------
// * Default Fixes / Resets
// ----------------------------------------------------------------------------

// // Remove the scrolling increment/decrement behavior from number input elements
// $(':input[type=number]').on('mousewheel', function(e){
// 	$(this).blur();
// });

// Exclude hidden elements from Parsley
if (typeof Parsley !== 'undefined' && typeof Parsley.options !== 'undefined') {
	Parsley.options.excluded = 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden';
}



// ----------------------------------------------------------------------------
// * Enhancements, Initializations, etc.
// ----------------------------------------------------------------------------


// Check for current page and set variables appropriately
// --------------------------------------------------
var isHome 					= false,
    isInside 				= false,
    isDigIn 				= false,
    isPairUp 				= false;

if (document.body.classList.contains('page-inside')) {
	isInside = true;

	if (document.body.classList.contains('page-dig-in')) {
		isDigIn = true;
	}
	else if (document.body.classList.contains('page-pair-up')) {
		isPairUp = true;
	}
}
else if (document.body.classList.contains('page-home')) {
	isHome = true;
}



// Age Check
// --------------------------------------------------
$('#age-check-modal-button-yes').on("click", function() {
	$('body').removeClass('age-check-not-verified').addClass('age-check-verified');

	Cookies.set('age-checked', "1");
});
$('#age-check-modal-button-no').on("click", function() {
	$('.age-check-modal-no-response').removeClass('hide');
});


var ageChecked = Cookies.get('age-checked');

if (ageChecked === "1") {
	$('body').addClass('age-check-verified');
}
else {
	$('body').addClass('age-check-not-verified');
}

$('.age-check--undetermined').removeClass('age-check--undetermined');



// Loading Status
// --------------------------------------------------
// When the page is loaded, add & remove classes from the body element
$(window).on('load', function() {
	$("body").addClass("page-loaded");
	$("body").removeClass("page-preloading");
});



// Home Page Operations
// --------------------------------------------------
if ( isHome ) {

	// Preloading!
	// Loading Progress script modified from https://codepen.io/kbav/pen/ORORAa
	// ---------------------------------------
	var queue        = new createjs.LoadQueue(),
	    $state       = $('#state'),
	    $progress    = $('#progress'),
	    $progressbar = $('#progressbar .bar');


	queue.on('complete',     onComplete);
	queue.on('error',        onError);
	queue.on('fileload',     onFileLoad);
	queue.on('fileprogress', onFileProgress);
	queue.on('progress',     onProgress);

	// Create queue of images to load. Lights first since they're smallest and will give more immediate feedback
	queue.loadManifest([
	  {
	    id:    '4',
	    src:   '/assets/images/obj--home--lights-string-back--posterized--imageoptim.png',
	    class: 'lights-string-1 lights-string',
	    htmlid:'lights-string-1',
	    dest:  'lights-string-1-wrapper'
	  },
	  {
	    id:    '5',
	    src:   '/assets/images/obj--home--lights-string-middle--posterized--imageoptim.png',
	    class: 'lights-string-2 lights-string',
	    htmlid:'lights-string-2',
	    dest:  'lights-string-2-wrapper'
	  },
	  {
	    id:    '6',
	    src:   '/assets/images/obj--home--lights-string-front--posterized--imageoptim.png',
	    class: 'lights-string-3 lights-string',
	    htmlid:'lights-string-3',
	    dest:  'lights-string-3-wrapper'
	  },
	  {
	    id:    '1',
	    src:   '/assets/images/bg--home--scene-1--stars-all--shadows-removed--posterized--imageoptim.png',
	    class: 'stars-image',
	    htmlid:'stars-image',
	    dest:  'stars-image-wrapper'
	  },
	  {
	    id:    '2',
	    src:   '/assets/images/obj--home--moon--full.png',
	    class: 'obj--moon',
	    htmlid:'obj--moon',
	    dest:  'global-logo'
	  },
	  {
	    id:    '3',
	    src:   '/assets/images/obj--home--trees-and-ground--manually-reoutlined--full--ground-fixed--posterized--imageoptim.png',
	    class: 'trees-and-ground-image',
	    htmlid:'trees-and-ground-image',
	    dest:  'trees-and-ground-image-wrapper'
	  },
	]);


	// ~ ScrollMagic! ~
	// ---------------------------------------


	// init Parallax Controller
	// --------------------------
	var parallaxController = new ScrollMagic.Controller();


	// build tween
	var tween = new TimelineMax ()
		.add([
			// TweenMax.to("#home-scene-1-container", 1, {backgroundPositionY: "+=7vh",  ease: Linear.easeNone}),
			TweenMax.to("#scene-1-bg-sky-wrapper", 1, {y: "+=16%",  ease: Linear.easeNone}),
			TweenMax.to("#stars-image-wrapper", 1, {y: "+=20%",  ease: Linear.easeNone}),
			TweenMax.to("#global-logo", 1, {transform: "scale(0.8)", opacity: "0",  ease: Linear.easeNone}),
			TweenMax.to("#lights-string-1-wrapper", 1, {y: "+=90%",  ease: Linear.easeNone}),
			TweenMax.to("#lights-string-2-wrapper", 1, {y: "+=52%",  ease: Linear.easeNone}),
			TweenMax.to("#lights-string-3-wrapper", 1, {y: "-=25%",  ease: Linear.easeNone}),
			// TweenMax.to("#screen-image-wrapper", 1, {bottom: "+=1%",  ease: Linear.easeNone})
		]);

	// get lights wrapper height
	var $lights_trigger_height    = $('#lights-wrapper').outerHeight();
	var $lights_parallax_duration = $lights_trigger_height * 1.5;
	var $lights_parallax_offset   = -($lights_parallax_duration); //($lights_trigger_height / 2 - $lights_parallax_duration);


	// build scene
	var parallaxScene = new ScrollMagic.Scene({triggerElement: "#lights-wrapper", duration: $lights_parallax_duration, offset: $lights_parallax_offset})
					.setTween(tween)
					// .addIndicators()
					.setPin("#global-logo", {pushFollowers: false})
					.addTo(parallaxController)
					.triggerHook(0);

	var parallaxViewed = 0;

	// When at the end of the parallax scene, initiate the flickity carousel's autoplay
	parallaxScene.on("end", function (event) {
		parallaxViewed++;

		if (parallaxViewed === 1) {
			$homeCarousel.addClass('has-reached-view').flickity('next');

			setTimeout(function() {
				$homeCarousel.flickity('next');
			}, 1000);
			setTimeout(function() {
				$homeCarousel.flickity('next').flickity('unpausePlayer');
			}, 2000);
		}
		else {
			return true;
		}
	});


	// init ScrollInViewController
	// --------------------------
	var scrollInViewController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "0.50", duration: "0"}});


	// build scroll class toggle scenes

	// Scene 2 / Dirt _________
	var dirt_copy_entry = new ScrollMagic.Scene({triggerElement: "#home-scene-2-heading", reverse:false})
					.setClassToggle(".home-scene-2-body-copy", "is-in-view") // add class toggle
					// .addIndicators()
					.addTo(scrollInViewController);

	// Meet Drink Articles _________
	var meet_drink_1 = new ScrollMagic.Scene({triggerElement: "#meet-violet-article", reverse:false})
					.setClassToggle("#meet-violet-article", "is-in-view") // add class toggle
					// .addIndicators()
					.addTo(scrollInViewController);
	var meet_drink_2 = new ScrollMagic.Scene({triggerElement: "#meet-ruby-article", reverse:false})
					.setClassToggle("#meet-ruby-article", "is-in-view") // add class toggle
					// .addIndicators()
					.addTo(scrollInViewController);

	// Bottom Rainbow Logo _________
	var bottom_logo_entry = new ScrollMagic.Scene({triggerElement: "#rainbow-logo-wrapper", reverse:false})
					.setClassToggle(".rainbow-logo-wrapper", "is-in-view") // add class toggle
					// .addIndicators()
					.addTo(scrollInViewController)
					.triggerHook(0.65);

	// Unicorn _________
	var unicorn_mobile_entry = new ScrollMagic.Scene({triggerElement: "#rainbow-logo-wrapper", reverse:false})
					.setClassToggle(".unicorn-wrapper", "rainbow-is-in-view") // add class toggle
					// .addIndicators()
					.addTo(scrollInViewController)
					.triggerHook(0.65);
	var unicorn_desktop_entry = new ScrollMagic.Scene({triggerElement: "#unicorn-wrapper", reverse:false})
					.setClassToggle(".unicorn-wrapper", "is-in-view") // add class toggle
					// .addIndicators()
					.addTo(scrollInViewController);
}



function onComplete(event) {
  // console.log('Complete', event);
  $state.text( $state.text() + '[All loaded]' );
  $progressbar.addClass('complete');
  document.body.className += ' preload-complete';
}

function onError(event) {
  // console.log('Error', event);
  $state.text( $state.text() + '[' + event.item.id + ' errored] ');
}

function onFileLoad(event) {
  // console.log('File loaded', event);
  $state.text( $state.text() + '[' + event.item.id + ' loaded] ');

  document.getElementById( event.item.dest )
      .appendChild(event.result)
      .className = event.item.class;
      // .id = event.item.htmlid;
}

function onFileProgress(event) {
  // console.log('File progress', event);
}

function onProgress(event) {
  var progress = Math.round(event.loaded * 100);

  // console.log('General progress', Math.round(event.loaded) * 100, event);
  $progress.text(progress + '%');
  $progressbar.css({
    'width': progress + '%'
  });
}



// Inside Page Operations
// --------------------------------------------------

if ( isInside ) {
	// init ScrollInViewController
	// --------------------------
	var scrollInViewInsidePgController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "0.40", duration: "0"}});


	// build scroll class toggle scenes

	// Inside Page reaching the main content _________
	var inside_pg_main_content_entry = new ScrollMagic.Scene({triggerElement: "#main-content-row", reverse:false})
					.setClassToggle("#main-content-row", "main-content-row-is-in-view") // add class toggle
					// .addIndicators()
					.addTo(scrollInViewInsidePgController);
}


// Dig In Page Operations
// ---------------------------------------
if ( isDigIn ) {
	// var blimpAnimate = document.getElementById("hero-bg-blimp-wrapper");
	TweenMax.from("#hero-bg-blimp-wrapper", 13, {left:"120%", bottom:"20%", rotation:"-20", ease: Back.easeOut.config(1), delay:"3"});
}

// Pair Up Page Operations
// ---------------------------------------
if ( isPairUp ) {

	// Unicorn Animation
	// --------------------------
	TweenMax.from("#hero-bg-unicorn-wrapper", 5, {right:"-40%", top:"100%", opacity:"0", rotation:"-20", ease: Power2.easeOut, delay:"3"});


	// Floating ingredient animations
	// --------------------------

	// init ScrollInViewController
	var scrollInViewPairUpController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "0.40", duration: "800", offset: -50}});

	// build tween
	// var ingredients_tween = TweenMax.to(".floating-ingredient", 1, {rotation: 540, ease: Linear.easeNone});
	var ingredients_tween = new TimelineMax ()
		.add([
			TweenMax.to("#hero-bg-floating-pomegranates-wrapper", 1, {y: "+=33%",  ease: Linear.easeNone}),
			TweenMax.to("#hero-bg-floating-blackberries-wrapper", 1, {y: "+=66%",  ease: Linear.easeNone}),
			TweenMax.to("#hero-bg-floating-lemons-wrapper",       1, {y: "+=100%",  ease: Linear.easeNone}),
		]);

	// build scene
	var ingredientsScene = new ScrollMagic.Scene({triggerElement: "#page-hero-area", duration: 1200})
					.setTween(ingredients_tween)
					// .addIndicators() // add indicators (requires plugin)
					// .setPin("#floating-pom-1", {pushFollowers: false})
					.addTo(scrollInViewPairUpController)
					.triggerHook(0);





	// // build tween
	// var tween = new TimelineMax ()
	// 	.add([
	// 		// TweenMax.to("#home-scene-1-container", 1, {backgroundPositionY: "+=7vh",  ease: Linear.easeNone}),
	// 		TweenMax.to("#scene-1-bg-sky-wrapper", 1, {y: "+=16%",  ease: Linear.easeNone}),
	// 		TweenMax.to("#stars-image-wrapper", 1, {y: "+=20%",  ease: Linear.easeNone}),
	// 		TweenMax.to("#global-logo", 1, {transform: "scale(0.8)", opacity: "0",  ease: Linear.easeNone}),
	// 		TweenMax.to("#lights-string-1-wrapper", 1, {y: "+=90%",  ease: Linear.easeNone}),
	// 		TweenMax.to("#lights-string-2-wrapper", 1, {y: "+=52%",  ease: Linear.easeNone}),
	// 		TweenMax.to("#lights-string-3-wrapper", 1, {y: "-=25%",  ease: Linear.easeNone}),
	// 		// TweenMax.to("#screen-image-wrapper", 1, {bottom: "+=1%",  ease: Linear.easeNone})
	// 	]);


	// // build scene
	// var parallaxScene = new ScrollMagic.Scene({triggerElement: "#lights-wrapper", duration: $lights_parallax_duration, offset: $lights_parallax_offset})
	// 				.setTween(tween)
	// 				// .addIndicators()
	// 				.setPin("#global-logo", {pushFollowers: false})
	// 				.addTo(parallaxController)
	// 				.triggerHook(0);

}






// Navigation / Menus
// --------------------------------------------------

// Variables
// -------------------------------
var $global_header 			= $('#global-header'),

	$nav_outer_wrapper 		= $('#global-outer-navigation-wrapper'),

	$nav_reveal_button 		= $('.hamburger-menu-button'),

	$nav_parent 			= $('.global-nav-item--has-children'),
	$nav_active_parent 		= $('.global-nav-item--has-children.active-parent'),
	$nav_parent_link 		= $('.global-nav-item--has-children > .global-nav-link'),
	$nav_child 				= $('.global-nav-child-list'),

	$main_nav 				= $('#global-main-nav'),
	$main_nav_parent 		= $('.global-main-nav-list > .global-nav-item--has-children'),

	$nav_link_opens_child 	= $('.global-nav-item--opens-child > .global-nav-link'),
	$nav_parent_has_button 	= $('.global-nav-item--has-child-nav-opener'),

	$utility_nav 			= $('#global-utility-nav');

	// $search_menu 			= $('#global-search-overlay'),
	// $search_input 			= $('#global-search-overlay__input'),
	// $search_submit 			= $('#global-search-overlay__submit'),
	// $search_close 			= $('.global-search-overlay__corner-closer'),
	// $search_close_btn 		= $('#global-search-overlay__close-button'),

	// $search_trigger 		= $('.global-search-trigger'),
	// $search_reveal_button	= $('.global-search-reveal-button');

var nav_close_timer;

var $homeCarousel 			= $('.screen-carousel');


// Functions
// ---------------------------------------


// Navigation opening
// --------------------------
// More complex function, that opens the menu for both mobile and desktop. Also can accept an argument to open a specific child menu.
function open_nav (the_menu) {

	// Use interpolation to take the passed child menu name and then select it below
	var interpolated_selector = ".menu-" + the_menu;
	$(interpolated_selector)
		.focus()
		.addClass('is-open submenu-is-open');
	$(interpolated_selector).children($nav_child).addClass('is-open');
	$(interpolated_selector).children('.child-nav-opener').addClass('is-active');

	// Feedback to the body: a child menu is open. Allows for making a backdrop show up over the content/beneath the menu.
	// $('body').addClass('child-menu-is-open');

	open_mobile_nav();
}

// Mobile nav toggling. This is a basic open function for the mobile menu.
function open_mobile_nav () {
	$('html').addClass('mobile-nav-is-open is-mobile-scroll-locked');
	$nav_reveal_button.addClass('is-active').attr('aria-expanded', 'true');
	$nav_outer_wrapper.addClass('is-active');
	$global_header.addClass('mobile-nav-is-open');
}


// Navigation closing
// --------------------------
function close_nav () {
	$('html').removeClass('mobile-nav-is-open is-mobile-scroll-locked');
	$nav_outer_wrapper.addClass('animating-out');
	$nav_reveal_button.removeClass('is-active').attr('aria-expanded', 'false');
	$global_header.removeClass('mobile-nav-is-open');

	// Close megamenus for mobile...before animation is finished
	$('.global-nav-item').removeClass('is-open submenu-is-open submenu-is-mobile-open');
	$('body').removeClass('child-menu-is-open');

	var nav_close_timer = setTimeout(function() {
		// Close megamenus for mobile...after animation is finished
		$('.child-nav-opener').removeClass('is-active');
		$nav_child.removeClass('is-open');

		$nav_outer_wrapper.removeClass('is-active');
		$nav_outer_wrapper.removeClass('animating-out');
	}, 250);

	// // also close the search
	// close_search();

	return false;
}





// Logic
// ---------------------------------------
$(document).ready(function() {

	// Navigation
	// --------------------------

	// Close the menu if it's currently active. Otherwise: run open_nav function.
	$nav_reveal_button.click(function() {
		if ($(this).hasClass('is-active')) {
			close_nav();
			this.blur(); // Remove the focus from the button so :focus styles don't show on touch screens

		} else {
			open_nav();
		}

		return false;
	});

	// If a special nav link set to open its child is clicked, open the child menu
	$nav_link_opens_child.click(function(e) {
		// Prevent link from being followed
		if (e.preventDefault) {
			e.preventDefault();
		} else {
			// old internet explorer
			e.returnValue = false;
		}

		// set the parent
		var $the_parent = $(this).parent();

		// remove is-open class if it's already there
		if ( $the_parent.hasClass('submenu-is-open') || $the_parent.hasClass('submenu-is-mobile-open') ) {
			$the_parent.removeClass('submenu-is-open submenu-is-mobile-open')
				.children('.global-nav-child-list').removeClass('is-open');
			$(this).blur();
		}
		// otherwise, add it
		else {
			$the_parent.addClass('submenu-is-open')
				.children('.global-nav-child-list').addClass('is-open');
		}
	});


	// If $nav_parent_has_button, generate button
	// ---------------------------------------
	// Dynamically add buttons after each child list
	// that listen for click, which triggers open/close of the child list
	// ---------------------------------------

	// Create the buttons and the function they run
	var child_nav_opener = $('<button class="child-nav-opener"><span class="sr-only">Open and close submenu</span><span class="opened-state"></span><span class="closed-state"></span></button>').click(function () {
		if ( $(this).hasClass('is-active') ) {
			$(this).removeClass('is-active');
			$(this).next().removeClass('is-open is-mobile-open');
			$(this).parent().removeClass('submenu-is-open submenu-is-mobile-open');
		}
		else {
			$(this).addClass('is-active');
			$(this).next().addClass('is-open');
			$(this).parent().addClass('submenu-is-open');
		}
	});

	// Add the buttons!
	$nav_parent_has_button.children('.global-nav-link').after(child_nav_opener);

	// If an .active-parent (our custom WP walker's way of noting a current page's navigation parent) is found, Open/Activate menus to have it open by default in the mobile menu
	$nav_active_parent.addClass('submenu-is-mobile-open');
	$nav_active_parent.children(child_nav_opener).addClass('is-active');
	$nav_active_parent.children('.global-nav-child-list').addClass('is-mobile-open');
	// ---------------------------------------



	// Home Flickity Carousel
	// --------------------------
	if ( isHome ) {
		$homeCarousel.flickity({
			lazyLoad: true,
			// autoPlay: 3500,
			wrapAround: true,
			pauseAutoPlayOnHover: false,
			draggable: false,
			// prevNextButtons: false,
			pageDots: false
		});

		// Start player at first slide but immediately pause it, waiting for user to scroll it in to view
		$homeCarousel.flickity('playPlayer').flickity('pausePlayer');

		var flkty 			= $homeCarousel.data('flickity'),
			numberOfCells 	= flkty.cells.length;

		// Check for Flickity arriving at the last slide
		$homeCarousel.on('select.flickity', function() {
			var cellAdjustedIndex 	= flkty.selectedIndex + 1;

			if ( cellAdjustedIndex === numberOfCells ) {
				// console.log("last cell! stopping player.");
				$(this).flickity('stopPlayer')
					.addClass('has-reached-end');
				return true;
			}
		});
	}

});



// Keyboard shortcuts with MouseTrap.js
// --------------------------------------------------
$(document).ready(function(){

	// press escape (ESC) to close menus
	Mousetrap.bind('esc', function() {
		close_nav();
		close_search();
	});

	// open the menu/nav
	Mousetrap.bind('u', function() {

		// Otherwise, if the mobile navigation is open, close it
		if ($global_header.hasClass('mobile-nav-is-open')) {
			close_nav();
		}

		// ...and if it's not open, open it
		else {
			open_nav();
		}
	});

	// // konami code!
	// Mousetrap.bind('up up down down left right left right b a', function() { //enter
	// 	$('body').addClass('konami-code-is-active');
	// });

});



// Bootstrap Dismissable Alert
// --------------------------------------------------
// Note: .alert CSS was built from the
// 		 ground up by @k_bav as an exercise.
//		 This JS is Bootstrap's for .alert dismissal.
// --------------------------------------------------
$(document).ready(function(){
	$(".alert").alert();
});



// Disappearing & reappearing Sticky Nav
// --------------------------------------------------
// via http://www.netvlies.nl/blog
// --------------------------------------------------
$(document).ready(function() {
	var a = $(".global-header"), e = 0, f = window.pageYOffset, d = a.outerHeight(), g = window.location.hostname; // formerly used window.scrollY but IE10 does not support it

	$(window).on("scroll", function() {
		f = e;
		e = window.pageYOffset; // formerly used window.scrollY but IE10 does not support it

		// Only proceed IF the mobile nav is not open
		if (!$global_header.hasClass('mobile-nav-is-open')) {
			f < e ? e > d && a.removeClass("show show-not-top fast") : (a.addClass("show show-not-top"), e < d && a.addClass("fast"));

			if (e <= 0) {
				a.removeClass("show-not-top");
			};
		};
	});
});
